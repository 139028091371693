<template>
    <modal ref="modalEditarInformacion" titulo="Editar información general" tamano="modal-lg" no-aceptar adicional="Guardar" @adicional="putData">
        <ValidationObserver ref="validacion">
            <div class="row mx-0">
                <div class="col-auto text-center px-0">
                    <slim-cropper ref="cropImagen" :options="slimOptions" class="border br-4 bg-white" style="height:160px;width:130px;background:#F8F9FF;">
                        <div slot="label" class="">
                            <img src="/img/modales/camera.svg" alt="" />
                        </div>
                    </slim-cropper>
                    <small class="f-13">
                        Foto
                    </small>
                </div>
                <div class="col">
                    <div class="row mx-0">
                        <div class="col">
                            <p class="f-13 ml-2">Nombre del proyecto</p>
                            <ValidationProvider v-slot="{errors}" rules="required|max:40" name="Nombre del proyecto">
                                <el-input v-model="model.nombre" placeholder="Nombre del proyecto" size="small" />
                                <p class="text-danger w-100 f-11"> {{ errors[0] }} </p>
                            </ValidationProvider>
                        </div>
                        <div class="col">
                            <p class="f-13 ml-2">Tipo</p>
                            <el-radio v-model="model.tipo" :label="1">
                                Masivo
                            </el-radio>
                            <el-radio v-model="model.tipo" :label="2">
                                Vip
                            </el-radio>
                        </div>
                    </div>
                    <div class="row mx-0 mt-3">
                        <div class="col">
                            <p class="f-13 ml-2">Descripción</p>
                            <ValidationProvider v-slot="{errors}" rules="required|max:150" name="Descripción">
                                <el-input v-model="model.descripcion" class="w-100" type="textarea" :rows="3" placeholder="Digite una descripción" maxlength="150" />
                                <p class="text-danger w-100 f-11"> {{ errors[0] }} </p>
                            </ValidationProvider>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row mx-0 mt-4">
                <div class="col">
                    <p class="f-13 ml-2">Empresa</p>
                    <ValidationProvider v-slot="{errors}" rules="required|max:250" name="Empresa">
                        <el-input v-model="model.empresa" placeholder="Empresa" size="small" />
                        <p class="text-danger w-100 f-11"> {{ errors[0] }} </p>
                    </ValidationProvider>
                </div>
                <div class="col">
                    <p class="f-13 ml-2">Vendedor</p>
                    <ValidationProvider v-slot="{errors}" rules="required|max:250" name="Vendedor"> 
                        <el-input v-model="model.vendedor" placeholder="Vendedor" size="small" />
                        <p class="text-danger w-100 f-11"> {{ errors[0] }} </p>
                    </ValidationProvider>
                </div>
                <div class="col">
                    <p class="f-13 ml-2">Cliente</p>
                    <ValidationProvider v-slot="{errors}" rules="required|max:250" name="Cliente">
                        <el-input v-model="model.cliente" placeholder="Cliente" size="small" />
                        <p class="text-danger w-100 f-11"> {{ errors[0] }} </p>
                    </ValidationProvider>
                </div>
				<div class="col">
                    <p class="f-13 ml-2">Identificación cliente</p>
                    <ValidationProvider v-slot="{errors}" rules="required|max:250" name="Empresa">
                        <el-input v-model="model.idCliente" placeholder="Empresa" size="small" />
                        <p class="text-danger w-100 f-11"> {{ errors[0] }} </p>
                    </ValidationProvider>
                </div>
            </div>
            <div class="row mx-0 mt-4 mb-4">
                <div class="col-auto">
                    <p class="f-14 f-600">Color del sistema</p>
                    <el-color-picker v-model="model.color" @change="changeColor" />
                </div>
                <div class="col-auto">
                    <p class="f-14 f-600">Preview del botón general</p>
                    <div class="btn-preview text-center cr-pointer" style="width:193px;">
                        Botón
                    </div>
                </div>
            </div>
        </ValidationObserver>
    </modal>
</template>

<script>
import Service from '~/services/proyectos/proyectos'

export default {
    data(){
        return {
            model: {
                nombre: null,
                descripcion: null,
                empresa: null,
                vendedor: null,
                cliente: null,
				idCliente: null,
                imagen: null,
                tipo: 1,
                color: '#9508EB'
            },
            slimOptions: {
                ratio: '1:1',
                label: 'Subir',
                buttonCancelLabel: 'Cancelar',
                buttonConfirmLabel: 'ok'
            },
        }
    },

    computed:{
        idProyecto(){
            return this.$usuario.proyecto;
        },
    },

    methods: {
        showModal(project){
            this.mapearData(project);
            this.$refs.modalEditarInformacion.toggle();
        },

        async mapearData(project){
            console.log(project);
            this.model.nombre = project.nombre;
            this.model.descripcion = project.descripcion;
            this.model.empresa = project.empresa;
            this.model.vendedor = project.vendedor;
            this.model.cliente = project.cliente;
			this.model.idCliente = project.idCliente;
            this.model.tipo = parseInt(project.tipo);
            this.model.color = "#"+project.color;
            
            if (!_.isNull(project.imagen)){
                this.$refs.cropImagen.set_image(project.imagen_firmada)
            } else {
                this.$refs.cropImagen.instanciaCrop.remove()
            }
            this.changeColor();
        },

        async putData(){
            try {
                const valid = await this.$refs.validacion.validate();
                if(!valid)return;

                let color = this.model.color.split('#')[1];
                this.model.color = color;

                this.model.imagen = this.$refs.cropImagen.instanciaCrop.dataBase64.output.image;

                const {data} = await Service.putInfoProject(this.idProyecto, this.model)
                if(data.exito){
                    this.notificacion('Mensaje', 'Se ha actualizado la información general', 'success');
                    this.clear();
                    this.$emit('update');
                    this.$refs.modalEditarInformacion.toggle();
                }
            } catch(e){
                this.error_catch(e)
            }
        },

        clear(){
            this.model.nombre = null;
            this.model.descripcion = null;
            this.model.empresa = null;
            this.model.vendedor = null;
            this.model.cliente = null;
            this.model.imagen = null;
            this.model.tipo = 1;
            this.model.color = '#9508EB';
            this.$refs.cropImagen.instanciaCrop.remove();
        },

        changeColor(){
            let btn = document.getElementsByClassName('btn-preview');
            btn[0].style.backgroundColor = this.model.color;
        },
    }
}
</script>
<style lang="scss" scoped>
.btn-back{
    width: 20px;
    height: 20px;
    color:#FFFFFF;
    border-radius: 20px;
    background-color: #000000;
}

.btn-preview{
    border-radius: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 193px;
    height: 36px;
    color: #FFFFFF;
    background-color: #9508EB;
    font-size: 18px;
}
</style>